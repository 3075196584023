import React, { useEffect, useState, useRef } from 'react'
import List from './List';
import './List.css';
import { useLocation, useParams } from 'react-router-dom';
import { fetchEvModelBySlug } from '../../../helpers/axios';

const capitalizeFirstLetter = (string) => {
    return string.replace(/\b\w/g, char => char.toUpperCase());
};

const CarList = () => {
    const location = useLocation();
    const { slug } = useParams();
    const [prop, setProp] = useState(null);
    const [carsByBrand, setCarsByBrand] = useState([]);
    const hasFetchedData = useRef(false);

    const items = [
        {
            id: 1,
            imageUrl: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Kia/EV6/8947/1654159762071/front-left-side-47.jpg',
            title: 'Item 1',
            description: 'This is a description for item 1.',
            price: '$10.00',
        },
        {
            id: 2,
            imageUrl: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Kia/EV6/8947/1654159762071/front-left-side-47.jpg',
            title: 'Item 2',
            description: 'This is a description for item 2.',
            price: '$20.00',
        },
        {
            id: 3,
            imageUrl: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Kia/EV6/8947/1654159762071/front-left-side-47.jpg',
            title: 'Item 3',
            description: 'This is a description for item 3.',
            price: '$30.00',
        },
        {
            id: 4,
            imageUrl: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Kia/EV6/8947/1654159762071/front-left-side-47.jpg',
            title: 'Item 3',
            description: 'This is a description for item 3.',
            price: '$30.00',
        },
        {
            id: 5,
            imageUrl: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Kia/EV6/8947/1654159762071/front-left-side-47.jpg',
            title: 'Item 3',
            description: 'This is a description for item 3.',
            price: '$30.00',
        },
        {
            id: 6,
            imageUrl: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Kia/EV6/8947/1654159762071/front-left-side-47.jpg',
            title: 'Item 3',
            description: 'This is a description for item 3.',
            price: '$30.00',
        },
        {
            id: 7,
            imageUrl: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Kia/EV6/8947/1654159762071/front-left-side-47.jpg',
            title: 'Item 3',
            description: 'This is a description for item 3.',
            price: '$30.00',
        },
        {
            id: 8,
            imageUrl: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Kia/EV6/8947/1654159762071/front-left-side-47.jpg',
            title: 'Item 3',
            description: 'This is a description for item 3.',
            price: '$30.00',
        },
        {
            id: 9,
            imageUrl: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Kia/EV6/8947/1654159762071/front-left-side-47.jpg',
            title: 'Item 3',
            description: 'This is a description for item 3.',
            price: '$30.00',
        },
        {
            id: 10,
            imageUrl: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Kia/EV6/8947/1654159762071/front-left-side-47.jpg',
            title: 'Item 3',
            description: 'This is a description for item 3.',
            price: '$30.00',
        },
        {
            id: 11,
            imageUrl: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Kia/EV6/8947/1654159762071/front-left-side-47.jpg',
            title: 'Item 3',
            description: 'This is a description for item 3.',
            price: '$30.00',
        },
        {
            id: 12,
            imageUrl: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Kia/EV6/8947/1654159762071/front-left-side-47.jpg',
            title: 'Item 3',
            description: 'This is a description for item 3.',
            price: '$30.00',
        },
        // Add more items as needed
    ];

    //var prop = location.state.state;
    async function getData(props) {
        console.log("props getData", props)
        try {
            const result = await fetchEvModelBySlug(props);
            console.log(result.message)
            const message = result.message
            if(message.length !== 0) {
              setCarsByBrand(result.message);
            }else{
                <div>No Data found</div>
            }
            //else{
            // }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Cars';
        console.log("params", slug)
         // Only fetch data if it hasn't been fetched already
    if (!hasFetchedData.current) {
        if (location.state && location.state.state) {
            console.log(location.state.state)
           // setProp(location.state.state.make);
            getData(location.state.state.make)
        } else {
            if (slug) {
                //setProp(slug.capitalizeFirstLetter)
                const capitalizedSlug = capitalizeFirstLetter(slug);
                getData(capitalizedSlug)
            }else{
                console.log("slug is empty")
            }
            //setError('No state passed through navigation.');
        }
        hasFetchedData.current = true;
    }else{
        console.log("not fetching", carsByBrand)
        //setCarsByBrand(carsByBrand);
    }
        
    }, [location.state,slug, carsByBrand]);

    return (
        <div className="main">
            {carsByBrand ? <List items={carsByBrand} /> : <p>Loading data...</p>}
            {/* <List items={prop} /> */}
        </div>
    );
};

export default CarList;
