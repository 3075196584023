import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import './style.css';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { postBlog } from '../../../../actions';



/**
* @author
* @function CreatePostPage
**/
//reactquill

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ]
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

export const CreatePostPage = (props) => {
  const type = useSelector(state => state.post);

  const [title, setTitle] = useState('');
  const [fileName, setFileName] = useState('');
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState('');
  const [files, setFiles] = useState('');
  const [base64URL, setbase64URL] = useState('');
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState('car');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


  //https://yarnpkg.com/package/react-quill


  const handleFileInputChange = e => {
    const file = e.target.files[0];
    // this.setFiles(e.target.files);
    setFileName(file.name);

    getBase64(file)
      .then(result => {
        setbase64URL(result);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getBase64 = (file) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  function AlertDismissibleExample() {
    const [show, setShow] = useState(true);
    console.log("presentng");
    if (show) {
      return (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
          <p>
            Change this and that and try again. Duis mollis, est non commodo
            luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
            Cras mattis consectetur purus sit amet fermentum.
          </p>
        </Alert>
      );
    }
    return <Button onClick={() => setShow(true)}>Show Alert</Button>;
  }


  function createNewPost(ev) {
    console.log('trigrred')
    ev.preventDefault();
    const data = new FormData();
    data.set('title', title);
    data.set('summary', summary);
    data.set('content', content);
    data.set('file', files[0]);
    data.set('imagebase64', base64URL);
    data.set('fileName', fileName);
    data.set('newsType', selectedOption)
    //console.log(files[0]);
    dispatch(postBlog(data))
    // axios.post(`${process.env.REACT_APP_SERVER_URL}/api/blog/createPost`, data)
    //   .then(function (response) {
        // window.location.reload();
    //   })
    //   .catch(function (error) {
    //     alert(error);
    //   });
  }

  function hideSpinner() {
    document.getElementById('post-span').innerHTML = 'Save Post';
    document.getElementById('post-spinner').hidden = 'true';
    document.getElementById('post_button').disabled = false;
  }


  function showSpinner() {
    document.getElementById('post-span').innerHTML = 'Saving...';
    document.getElementById('post-spinner').hidden = 'false';
    document.getElementById('post_button').disabled = true;
  }

  useEffect(() => {
    type.loading ? showSpinner() : hideSpinner()

    if (type.error) {
      hideSpinner()
      alert(type.error)
    }else{
      if(type.post) {
        alert(type.post)
        //window.location.reload();
      }
    }
    console.log(type.post)

  }, [type])

  return (
    <main>
      <div>
            <h3>Select an option:</h3>
            <div className="radio-group">
            <div className="radio-option">
                <input
                    type="radio"
                    id="car"
                    name="options"
                    value="car"
                    checked={selectedOption === 'car'}
                    onChange={handleOptionChange}
                />
                <label htmlFor="option1">Car</label>
            </div>
            <div className="radio-option">
                <input
                    type="radio"
                    id="bike"
                    name="options"
                    value="bike"
                    checked={selectedOption === 'bike'}
                    onChange={handleOptionChange}
                />
                <label htmlFor="option2">Bike</label>
            </div>
            <p>Selected Option: {selectedOption}</p>
        </div>
        </div>
      <form>
        <input type="title"
          placeholder={'Title'}
          value={title}
          onChange={ev => setTitle(ev.target.value)} />
        <input type="summary"
          placeholder={'Summary'}
          value={summary}
          onChange={ev => setSummary(ev.target.value)} />
        {/* <input type="file"
        onChange={ev => setFiles(ev.target.files)} /> */}
        <input type="file"
          onChange={handleFileInputChange} />
        <ReactQuill
          value={content}
          onChange={newValue => setContent(newValue)}
          modules={modules}
          formats={formats} />

        <Button id='post_button' style={{ marginTop: '5px' }} variant="primary" enabled onClick={createNewPost}>
          <Spinner id='post-spinner'
            hidden='true'
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span id='post-span'>
            Create Post
          </span>
        </Button>
        {/* <button style={{ marginTop: '5px' }}>
        Create post
      </button> */}
      </form>
    </main>

  )

}