import React, { useState, useEffect } from 'react';
import { fetchEvMake, performAddModel } from '../../../helpers/axios';
import { useNavigate } from 'react-router-dom'; // Only for React Router usage
import { handleAuthorizationError } from '../../../helpers/errorutils';


const ModelForm = () => {
  const [formData, setFormData] = useState({
    make: '',
    makeSlug: '',
    name: '',
    carSlug: '',  // This will be managed internally
    isTrending: false,
    isUpcoming: false,
    title: '',
    description: '',
    images: [''],
    baseVariant: [],
    colors: [],
  });
  const navigate = useNavigate();
  const [makes, setMakes] = useState([]);

  useEffect(() => {
    // Fetch makes data on component mount
    const fetchMakes = async () => {
      try {
        const result = await fetchEvMake();
        console.log(result.message); // Logging fetched data
        setMakes(result.message); // Assume result.message is an array of makes
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchMakes();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === 'checkbox' ? checked : value;
   
    // Update formData state
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData, [name]: updatedValue };

      // Update carSlug if name changes
      if (name === 'name') {
        newFormData.carSlug = generateCarSlug(updatedValue);
      }

      // Update makeSlug if make changes
      if (name === 'make') {
        const selectedMakeObj = makes.find(make => make.make === value);
        newFormData.makeSlug = selectedMakeObj ? generateSlug(selectedMakeObj.make) : '';
      }

      return newFormData;
    });
  };

  const generateCarSlug = (text) => {
    return text
      .toLowerCase()        // Convert to lowercase
      .replace(/\s+/g, '_') // Replace spaces with underscores
      .replace(/[^\w\-]+/g, '') // Remove non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple hyphens with a single hyphen
      .replace(/^-+/, '') // Remove leading hyphens
      .replace(/-+$/, ''); // Remove trailing hyphens
  };

  const generateSlug = (text) => {
    return text
      .toLowerCase()        // Convert to lowercase
      .replace(/\s+/g, '_') // Replace spaces with underscores
      .replace(/[^\w\-]+/g, '') // Remove non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple hyphens with a single hyphen
      .replace(/^-+/, '') // Remove leading hyphens
      .replace(/-+$/, ''); // Remove trailing hyphens
  };

  const handleImageChange = (index, value) => {
    const updatedImages = [...formData.images];
    updatedImages[index] = value;
    setFormData({ ...formData, images: updatedImages });
  };

  const addImageField = () => {
    setFormData({ ...formData, images: [...formData.images, ''] });
  };

  const removeImageField = (index) => {
    const updatedImages = formData.images.filter((_, i) => i !== index);
    setFormData({ ...formData, images: updatedImages });
  };

  const postModel = async (formData) => {
    try {
      const result = await performAddModel(formData);
      console.log('Model added successfully:', result);
    } catch (error) {
      handleAuthorizationError(error, navigate);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("formData",formData);
    postModel(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Make:</label>
        <select
          name="make"
          value={formData.make}
          onChange={handleChange}
        >
          <option value="">Select Make</option>
          {makes.map((make, index) => (
            <option key={index} value={make.make}>{make.make}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Make Slug:</label>
        <input 
          type="text" 
          name="makeSlug" 
          value={formData.makeSlug} 
          onChange={handleChange} 
          disabled // Disabling the input field for makeSlug
        />
      </div>
      <div>
        <label>Name:</label>
        <input 
          type="text" 
          name="name" 
          value={formData.name} 
          onChange={handleChange} 
        />
      </div>
      {/* Removed the Car Slug input field */}
      <div>
        <label>Trending:</label>
        <input 
          type="checkbox" 
          name="isTrending" 
          checked={formData.isTrending} 
          onChange={handleChange} 
        />
      </div>
      <div>
        <label>Upcoming:</label>
        <input 
          type="checkbox" 
          name="isUpcoming" 
          checked={formData.isUpcoming} 
          onChange={handleChange} 
        />
      </div>
      <div>
        <label>Title:</label>
        <input 
          type="text" 
          name="title" 
          value={formData.title} 
          onChange={handleChange} 
        />
      </div>
      <div>
        <label>Description:</label>
        <textarea 
          name="description" 
          value={formData.description} 
          onChange={handleChange} 
        ></textarea>
      </div>
      <div>
        <label>Images:</label>
        {formData.images.map((image, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <input
              type="text"
              value={image}
              onChange={(e) => handleImageChange(index, e.target.value)}
              placeholder="Image URL"
              style={{ flex: 1, marginRight: '8px' }}
            />
            <button
              type="button"
              onClick={() => removeImageField(index)}
              style={{ width: '100px' }} // Set a fixed width for the button
            >
              Remove
            </button>
          </div>
        ))}
        <button 
          type="button" 
          onClick={addImageField}
          style={{ marginRight: '16px' }} // Space between Add Image and Submit
        >
          Add Image
        </button>
      </div>
      <div style={{ marginTop: '16px' }}> {/* Add space above the Submit button */}
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};

export default ModelForm;
