import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumbs = () => {
  const location = useLocation();

  const breadcrumbNameMap = {
    '/': 'Home',
    '/newcars': 'New Cars',
    '/hybrid_cars': 'Hybrid Cars',
    '/createblog': 'Create Blog',
    '/news_reviews': 'News & Reviews',
    '/hydrogen_cars': 'Hydrogen Cars',
    '/aboutus': 'About Us',
    '/disclaimer': 'Disclaimer',
    '/termandconditions': 'Terms & Conditions',
  };

  const generateBreadcrumbs = () => {
    const pathnames = location.pathname.split('/').filter((x) => x);
    return (
      <div className="breadcrumbs">
        <Link to="/">Home</Link>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          const name = breadcrumbNameMap[`/${value}`] || value;
          
          // Special cases for routes with dynamic segments
          if (to.includes('/news_reviews/')) {
            return (
              <span key={to}>
                {' > '}
                {isLast ? (
                  <span>{value}</span>
                ) : (
                  <Link to={to}>News & Reviews</Link>
                )}
              </span>
            );
          } else if (to.includes('/:make/:carSlug')) {
            return (
              <span key={to}>
                {' > '}
                {isLast ? (
                  <span>{value}</span>
                ) : (
                  <Link to={to}>{value}</Link>
                )}
              </span>
            );
          }

          return (
            <span key={to}>
              {' > '}
              {isLast ? (
                <span>{name}</span>
              ) : (
                <Link to={to}>{name}</Link>
              )}
            </span>
          );
        })}
      </div>
    );
  };

  return <nav>{generateBreadcrumbs()}</nav>;
};

export default Breadcrumbs;
