import React, { useState } from 'react'
import { IoIosPerson } from 'react-icons/io';
import { Link, NavLink } from 'react-router-dom'
import './style.css';
import Lottie from 'react-lottie';
import * as animationData from '../data/beta.json'


/**
* @author
* @function Header
**/

const Header = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid'
        }
      };

    return (
        <nav className='header-nav'>

            <div style={{
                display:'inline-flex'
            }}>
            <Link to='/' className='title'>
                TheEVmotors<span></span>
            </Link>
            {/* <Lottie className='title' options={defaultOptions}
              height='auto'
              width={100}
              /> */}
            </div>
            
           
            <div className='menu' onClick={() => { setMenuOpen(!menuOpen) }
            }>
                <span></span>
                <span></span>
                <span></span>
            </div>

            
            <ul className={menuOpen ? "open" : ""}>
                <li>
                    <NavLink to='/newcars' onClick={() => { setMenuOpen(!menuOpen) }
                    }>Electric Cars</NavLink>
                </li>
                <li>
                    <NavLink to='/hybrid_cars' onClick={() => { setMenuOpen(!menuOpen) }
                    }>Hybrid Cars</NavLink>
                </li>
                <li>
                    <NavLink to='/hydrogen_cars' onClick={() => { setMenuOpen(!menuOpen) }
                    }>Hydrogen Cars</NavLink>
                </li>
                <li>
                    <NavLink to='/news_reviews' onClick={() => { setMenuOpen(!menuOpen) }
                    }>Car News</NavLink>
                </li>
                <li>
                    <NavLink to='/bike/news_reviews' onClick={() => { setMenuOpen(!menuOpen) }
                    }>Bikes News</NavLink>
                </li>
                {/* <li>
                    <NavLink to='/createblog' onClick={() => { setMenuOpen(!menuOpen) }
                    }>Post</NavLink>
                </li> */}
                {/* <li>
                    <NavLink to='/details' onClick={() => { setMenuOpen(!menuOpen) }
                    }>Details</NavLink>
                </li> */}
            </ul>

            {/* <div className='login'>
                <IoIosPerson>Login</IoIosPerson>
            </div> */}
        </nav>
    )

}

export default Header;