import axios from "axios";

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}`,
    // withCredentials: true
});

//Add a request interceptor to attach the token to each request
axiosInstance.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    console.log("token", token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });

export const fetchPopularPosts = async () => {
    try {
        const response = await axiosInstance.get('/api/blog/post/top-posts');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchEvMake = async () => {
    try {
        const response = await axiosInstance.get('/api/get/make');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchEvModel = async () => {
    try {
        const response = await axiosInstance.get('/api/get/model');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchEvAllModel = async () => {
    try {
        const response = await axiosInstance.get('/api/get/allModel');
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const fetchEVHome = async () => {
    try {
        const response = await axiosInstance.get('/api/get/home');
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const fetchEvModelBySlug = async (slug) => {
    try {
        const response = await axiosInstance.get(`/api/get/model/${slug}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const fetchParticularModel = async (brand, model, variant) => {
    try {
        const response = await axiosInstance.get("/api/get/" + brand + "/" + model + "/" + variant)
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchAllVariantModel = async (brand, model) => {
    try {
        const response = await axiosInstance.get("/api/get/" + brand + "/" + model)
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const performLogin = async (email, password) => {
    try {
        const response = await axiosInstance.post("/api/signin", {
            email: email,
            password: password,
        })
        const { token } = response.data;
        // Store the token and user data in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const performAddModel = async (formData) => {
    try {
        const response = await axiosInstance.post("/api/add/model", formData)
        console.log(response);
        return response.data;
    }catch (error) {
        throw error;
    }
};

export const performAddVariant = async (formData) => {
    try {
        const response = await axiosInstance.post("/api/add/model/variant", formData)
        return response.data;
    }catch (error) {
        throw error;
    }
};

export const performAddLead = async (formData) => {
    try {
        const response = await axiosInstance.post("/api/add/lead", formData);
        return response.data;
    }catch (error) {
        throw error;
    }
};

export default axiosInstance;