import React, { useEffect, useState, useRef } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
//import './style.css';
import './home.style.css';
// import Badge from 'react-bootstrap/Badge';
// import Button from 'react-bootstrap/Button';
// import handleImage from './../../../images/logo/placeholder.png'
// import { Placeholder } from 'react-bootstrap';
import { SEO } from '../SEO';
import { CustomPopup } from '../../../containers/common/customPopup';
import { useLocation, useNavigate } from "react-router-dom";
import ListWithTabBar from './FeaturedCars/featuredCars';
import axiosInstance, { fetchEVHome, fetchEvMake } from '../../../helpers/axios';
// import { getStatusData } from '@progress/kendo-react-grid';
import GridItems from '../example/GridItems';
import BlogCard from '../Blog/BlogCard/BlogCard';

/**
* @author
* @function HomePage
**/





export const HomePage = (props) => {

  const cars = [
    {
      name: "Kia EV6",
      price: "13.89 Lakh",
      image: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Kia/EV6/8947/1654159762071/front-left-side-47.jpg',
      isNewLaunched: true
    },
    {
      name: "Tata Nexon EV",
      price: "60.89 Lakh",
      image: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Tata/Nexon-EV-2023/11024/1694146347051/front-left-side-47.jpg',
      isNewLaunched: true

    },
    {
      name: "Tata Tiago EV",
      price: "15.89 Lakh",
      image: 'https://autoexpo.tatamotors.com/images/product/electric-vehicles/tiago-ev-inner.png',
      isNewLaunched: false
    },
    {
      name: "MG Comet EV",
      price: "15.89 Lakh",
      image: 'https://mgmotor.scene7.com/is/image/mgmotorstage/cm-img-dsc-075?$mg-rgb-tablet-image-responsive$',
      isNewLaunched: false
    },
    {
      name: "BMW i7",
      price: "15.89 Lakh",
      image: 'https://www.bmw.in/content/dam/bmw/common/all-models/i-series/i7/2022/Highlights/bmw-7-series-i7-ms-electric-driving.jpg/jcr:content/renditions/cq5dam.resized.img.585.low.time1687171609632.jpg',
      isNewLaunched: true
    },
    {
      name: "Hyundai Kona Electric",
      price: "15.89 Lakh",
      image: 'https://media.ed.edmunds-media.com/hyundai/kona/2022/oem/2022_hyundai_kona_4dr-suv_limited_fq_oem_1_1600.jpg',
      isNewLaunched: false
    },
    {
      name: "BMW iX",
      price: "15.89 Lakh",
      image: 'https://www.bmw.in/content/dam/bmw/common/all-models/i-series/i20-new/onepager/bmw-x-series-ix-onepager-ms-range.jpg/jcr:content/renditions/cq5dam.resized.img.585.low.time1617023894805.jpg',
      isNewLaunched: true
    },
    {
      name: "Tata Tigor EV",
      price: "15.89 Lakh",
      image: 'https://stimg.cardekho.com/images/carexteriorimages/630x420/Tata/Nexon-EV-2023/11024/1694146347051/front-left-side-47.jpg',
      isNewLaunched: false
    },
    {
      name: "MG ZS EV",
      price: "15.89 Lakh",
      image: 'https://mgmotor.scene7.com/is/image/mgmotor/zs-img-dsc-0305?$mg-rgb-desktop-image-responsive$',
      isNewLaunched: true
    },
    {
      name: "BMW i4",
      price: "15.89 Lakh",
      image: 'https://www.bmwusa.com/content/dam/bmwusa/common/vehicles/2023/my24/all-electric/i4/gran-coupe/overview/desktop/BMW-MY24-i4-Overview-Performance-02-Desktop.jpg',
      isNewLaunched: false
    },
    {
      name: "Lotus Eletre",
      price: "15.89 Lakh",
      image: 'https://wlt-p-001.sitecorecontenthub.cloud/api/public/content/dfb751b082a145cfb83dac5bcb68c2a5?v=d22fd31e',
      isNewLaunched: true
    },
    {
      name: "Toyota bZ4X",
      price: "70 Lakh",
      image: ' https://stimg.cardekho.com/images/car-images/630x420/Toyota/bZ4X/9538/1673357224590/front-left-side-47.jpg',
      isNewLaunched: true
    }


  ]

  const upcomingCars = [

    {
      name: "Tata Sierra EV",
      price: "70 Lakh",
      image: ' https://ik.imagekit.io/latigid/sierraev.jpeg?updatedAt=1704739750123',
      isNewLaunched: false,
      isUpcoming: true
    },
    {
      name: "Tata Altroz EV",
      price: "70 Lakh",
      image: ' https://evmotors.s3.ap-south-1.amazonaws.com/productimages/car1.jpg',
      isNewLaunched: false,
      isUpcoming: true
    },
    {
      name: "Tata Safari EV",
      price: "70 Lakh",
      image: ' https://ik.imagekit.io/latigid/safari.jpeg?updatedAt=1704739750187',
      isNewLaunched: false,
      isUpcoming: true
    },
    {
      name: "Tata Harrier EV",
      price: "70 Lakh",
      image: 'https://ik.imagekit.io/latigid/HarrierEv.jpeg?updatedAt=1704739750148',
      isNewLaunched: false,
      isUpcoming: true
    },
    {
      name: "Ioniq6",
      price: "70 Lakh",
      image: ' https://ik.imagekit.io/latigid/ioniq6.jpeg?updatedAt=1704739750307',
      isNewLaunched: false,
      isUpcoming: true
    },
    {
      name: "Creta EV",
      price: "70 Lakh",
      image: ' https://ik.imagekit.io/latigid/creata.jpeg?updatedAt=1704739750068',
      isNewLaunched: false,
      isUpcoming: true
    },
    {
      name: "Mahindra XUV.e8",
      price: "70 Lakh",
      image: 'https://ik.imagekit.io/latigid/xuve8.jpeg?updatedAt=1704739747925',
      isNewLaunched: false,
      isUpcoming: true
    },
    {
      name: "Mahindra XUV.e9",
      price: "70 Lakh",
      image: ' https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMtdLTW1VpiO08v4gw4XGVB_zLTYyH5MpVbw&usqp=CAU',
      isNewLaunched: false,
      isUpcoming: true
    },
    {
      name: "Mahindra BE.05",
      price: "70 Lakh",
      image: ' https://ik.imagekit.io/latigid/be05.jpeg?updatedAt=1704739747922',
      isNewLaunched: false,
      isUpcoming: true
    },
    {
      name: "Mahindra BE.07",
      price: "70 Lakh",
      image: ' https://ik.imagekit.io/latigid/be07.jpeg?updatedAt=1704739747887',
      isNewLaunched: false,
      isUpcoming: true
    },
    {
      name: "Mahindra BE.09",
      price: "70 Lakh",
      image: ' https://ik.imagekit.io/latigid/be09.jpeg?updatedAt=1704739747891',
      isNewLaunched: false,
      isUpcoming: true
    },
    {
      name: "Kia EV5",
      price: "70 Lakh",
      image: ' https://ik.imagekit.io/latigid/kiaev5.jpeg?updatedAt=1704739747928',
      isNewLaunched: false,
      isUpcoming: true
    },
    // {
    //   name: "Kia EV6",
    //   price: "70 Lakh",
    //   image: ' https://ik.imagekit.io/latigid/kiaev9.jpeg?updatedAt=1704739747931',
    //   isNewLaunched: false,
    //   isUpcoming: true
    // },
    // {
    //   name: "Jeep Avenger",
    //   price: "70 Lakh",
    //   image: 'https://ik.imagekit.io/latigid/avenger.jpeg?updatedAt=1704739747895',
    //   isNewLaunched: false,
    //   isUpcoming: true
    // },
    // {
    //   name: "BYD seal",
    //   price: "70 Lakh",
    //   image: ' https://ik.imagekit.io/latigid/Bydseal.jpeg?updatedAt=1704739747815',
    //   isNewLaunched: false,
    //   isUpcoming: true
    // }


  ]

  const blogData = [
    {
      id: 1,
      title: "First Blog Post",
      image: "https://via.placeholder.com/150",
      summary: "This is the summary of the first blog post."
    },
    {
      id: 2,
      title: "Second Blog Post",
      image: "https://via.placeholder.com/150",
      summary: "This is the summary of the second blog post."
    },
    {
      id: 3,
      title: "Second Blog Post",
      image: "https://via.placeholder.com/150",
      summary: "This is the summary of the second blog post."
    },
    {
      id: 4,
      title: "Second Blog Post",
      image: "https://via.placeholder.com/150",
      summary: "This is the summary of the second blog post."
    },
    {
      id: 5,
      title: "Second Blog Post",
      image: "https://via.placeholder.com/150",
      summary: "This is the summary of the second blog post."
    },
    {
      id: 6,
      title: "Second Blog Post",
      image: "https://via.placeholder.com/150",
      summary: "This is the summary of the second blog post."
    },
    // Add more blog posts here
  ];

  const images = [
    {
      src: "https://s7ap1.scene7.com/is/image/tatapassenger/digital-banner-01?$B-BG-1440-500-S$&fit=crop&fmt=jpg",
      alt: "Image 1",
      clickAction: "https://ev.tatamotors.com/curvv/ev/register-interest.html",
      text: "Launched\nTata Curvv EV"
    },
    {
      src: "https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5-2024/highlights/ioniq5-highlights-kv-pc.jpg",
      alt: "Image 3",
      clickAction: "https://www.hyundai.com/worldwide/en",
      text: "Hyundai\nIoniq 5"
    },

    // Add more images as needed
  ];

  // const [cars_list] = useState(cars)
  // const [filterList] = useState(upcomingCars.filter((car) => car.isUpcoming == true))
  //const navigate = useNavigate();
  //const [tabs, setTabs] = useState([]);
  // const parseJSON = (value) => {
  //   try {
  //     return JSON.parse(value);
  //   } catch (e) {
  //     console.error('Parsing error on', value);
  //     return null;
  //   }
  // };


  const [tabs, setTabs] = useState([]);
  //const [value, setValue] = useState(0);
  //const isMounted = useRef(false);
  const hasFetchedData = useRef(false);
  const location = useLocation();
  const [makes, setMakes] = useState([]);
  const [items, setItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  let navigate = useNavigate();


  async function getData() {
    try {
      const result = await fetchEVHome();
      setTabs(result.featuredModel)
      sessionStorage.setItem('homeData', result.featuredModel); // Save data to sessionStorage

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  function fetchPosts() {
    axiosInstance.get(`/api/blog/getPosts?page=${1}`).then(res => {
      console.log("fetchPosts", res.data.message);
      if (res.status === 200) {
        setItems(res.data.message);
      }
    }).catch(error => {
      console.log(error);
      //Cuserror();
    });
  }
  const onClickCarsoule = (index) => {
    const url = images[index].clickAction;
    window.open(url, '_blank'); // Open the URL in a new tab    
    //window.location.href = 'https://ev.tatamotors.com/curvv/ev/register-interest.html';
  };

  async function fetchData() {
    try {
      const result = await fetchEvMake();
      console.log(result.message)
      setMakes(result.message);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if (!hasFetchedData.current) {
      getData();
      fetchData();
      fetchPosts()
      hasFetchedData.current = true;
    } else {
      console.log("not fetching")
    }
    // const storedData = sessionStorage.getItem('homeData');
    // if (storedData) {
    //   setTabs(storedData);
    // } else {
    //   // Use the data from sessionStorage if available
    //   console.log('Component is revisited, not fetching data');
    // }
  }, [location.key, makes, tabs])

  // Function to handle slide change
  const handleOnChange = (index) => {
    setCurrentIndex(index);
  };

  function clicked() {
    navigate(`/news_reviews`)
  }
  return (
    <div className='mainContainer'>
      <SEO
        title='Home'
        description='Your ultimate destination for comprehensive electric vehicle (EV) reviews,news, and insights.'
        name='EvMotors'
        type='article'
        link='https://www.theevmotors.in/'
      />
      <Carousel
        // showStatus={false}
        // dynamicHeight={true}
        renderThumbs={() => { }}
        // autoPlay={true}
        // infiniteLoop={true}
        // showArrows={true}
        // showIndicators={false}
        selectedItem={currentIndex}
        onChange={handleOnChange}
        showArrows={false}
        showThumbs={false}
        infiniteLoop={true}
        useKeyboardArrows={true}
        autoPlay={true}
        stopOnHover={true}
        swipeable={true}
        dynamicHeight={true}
        onClickItem={onClickCarsoule}>
        {/* {images.map((image, index) => (
        <img style={{ 
          width: '100%',
          height: 'auto',
          cursor: 'pointer',
          maxHeight: '80%',
          objectFit: 'cover' 
          }} key={index} src={image.src} alt={image.alt} />
          
        ))} */}
        {images.map((image, index) => (
          <div key={index} style={{ position: 'relative', width: '100%', height: '100%' }}>
            <img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                cursor: 'pointer'
              }}
              src={image.src}
              alt={image.alt}
            />
            <div style={{
              position: 'absolute',
              bottom: '20px', // Position text from the bottom
              left: '10%',
              transform: 'translateX(-20%)',
              color: 'white',
              background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
              padding: '10px',
              borderRadius: '5px',
              zIndex: 1,
              width: '30%', // Ensure text fits within the image
              textAlign: 'center' // Center align text
            }}>
              <h2 style={{ margin: 0 }}>
                {image.text.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </h2>
            </div>
          </div>
        ))}

      </Carousel>


      <div>
        <CustomPopup />
        <section className='section1'>
          <div>
            <div className='space'>
              <ListWithTabBar tabs={tabs} />
            </div>

            <div className='space'>
              <GridItems items={makes} />
            </div>

            <div className='space'>
              <div className="blog-section">
                <div className="header">
                  <h2>Latest News</h2>
                  <div className="view-more-link" onClick={() => { clicked() }}>
                    View More
                  </div>
                </div>

                <div className="blog-list">
                  {items.map((blog, index) => (
                    <BlogCard key={index} blog={blog} />
                  ))}
                </div>
              </div>

            </div>
          </div>
        </section>

        {/* aside start from here */}
        <aside className='aside'>
          <img src='https://tpc.googlesyndication.com/simgad/18320962566358187046' alt='AD' />

        </aside>

      </div>
    </div>
  )

}
