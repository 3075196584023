import React from 'react';

const SignOut = ({ onSignOut }) => {
  const handleSignOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    onSignOut(); // Notify parent component on sign-out
  };

  return (
    <div className="signout-wrapper">
      <button onClick={handleSignOut} className="signout-button">
        Sign Out
      </button>
    </div>
  );
};

export default SignOut;
